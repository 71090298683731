import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import jwt_decode from "jwt-decode";
import { environment } from "../../environments/environment";
import { Store } from "@ngxs/store";
import { SetLoggedInUser } from "../state/action/loggedinuser.action";

@Injectable({
	providedIn: "root",
})
export class AppinitService {
	refreshToken: string;
	api_url = environment.api_url;
	server_url = environment.server_url;

	constructor(private http: HttpClient, private store: Store) {}

	runAtAppStartUp() {
		return new Promise<void>(async (resolve, reject) => {
			this.refreshToken = localStorage.getItem("refresh_token") || null;
			if (this.refreshToken) {
				try {
					let currentUserDecoded: any = jwt_decode(this.refreshToken);
					const response: any = await this.http.post(this.api_url + "/users/select", { slug: { id: currentUserDecoded.id } }).toPromise();
					if (response.status) {
						if (response.data.role_seeker) {
							// setting the loggedin user state(seeker)
							this.store.dispatch(
								new SetLoggedInUser({
									id: response.data.id,
									email: response.data.email,
									name: response.data.seeker[0].fullname,
									userType: "seeker",
									userTypeId: response.data.seeker[0].id,
									avatar: `${this.server_url}` + response.data.seeker[0].avatar,
									slug: response.data.seeker[0].slug,
									isLoggedIn: true,
								})
							);
						} else if (response.data.role_provider) {
							// setting the loggedin user state(innovator)
							this.store.dispatch(
								new SetLoggedInUser({
									id: response.data.id,
									email: response.data.email,
									name: response.data.provider[0].fullname,
									userType: "provider",
									userTypeId: response.data.provider[0].id,
									avatar: `${this.server_url}` + response.data.provider[0].avatar,
									slug: response.data.provider[0].slug,
									isLoggedIn: true,
								})
							);
						}
					}
				} catch (err) {
					localStorage.clear();
					console.log(err);
				}
			}
			resolve();
		});
	}
}
