import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import * as _ from "lodash";
import { tap } from "rxjs/operators";
import { Store } from "@ngxs/store";
import { ClearLoggedInUserSession } from "../state/action/loggedinuser.action";
import { Router } from "@angular/router";

@Injectable({
	providedIn: "root",
})
export class AuthService {
	api_url = environment.api_url;
	constructor(private http: HttpClient, private _store: Store, private router: Router) {}

	async login(credentials) {
		return await this.http
			.post(`${this.api_url}/auth/login`, {
				data: {
					email: credentials.email,
					password: credentials.password,
				},
			})
			.pipe(
				tap((tokens: any) => {
					this.storeTokens(tokens);
				})
			)
			.toPromise();
	}

	refreshToken() {
		const currentToken = localStorage.getItem("refresh_token") || null;
		return this.http
			.post(`${this.api_url}/auth/refresh_token`, {
				refresh_token: currentToken,
			})
			.pipe(
				tap((tokens: any) => {
					this.storeTokens(tokens);
				})
			);
	}

	private storeTokens(tokens: any) {
		localStorage.setItem("access_token", tokens.access_token);
		localStorage.setItem("refresh_token", tokens.refresh_token);
	}

	logout() {
		localStorage.removeItem("access_token");
		localStorage.removeItem("refresh_token");
		this._store.dispatch(new ClearLoggedInUserSession());
		this.router.navigateByUrl("/login").then(() => {
			window.location.reload();
		});
	}
}
