import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import * as _ from "lodash";

@Injectable({
	providedIn: "root",
})
export class LoginService {
	api_url = environment.api_url;

	constructor(private http: HttpClient) {}

	async loginApi(data: any) {
		let post_data = { data };
		return await this.http.post(this.api_url + "/users/login", post_data).toPromise();
	}

	getSelectedSectorListAPI(type: string, data: any) {
		if (type == "seeker") {
			return this.http.post(this.api_url + "/challenge_sectors_seekers/select_mul", data);
		} else if (type == "provider") {
			return this.http.post(this.api_url + "/challenge_sectors_and_providers/select_mul", data);
		}
	}

	getUserSelectedSectors(type: string, data) {
		var tmp_arr = [];
		return new Promise((resolve, reject) => {
			if (type == "seeker") {
				this.http.post(this.api_url + "/challenge_sectors_seekers/select_mul", data).subscribe((response: any) => {
					if (response.data.length) {
						_.each(response.data, (itm, idx) => {
							tmp_arr.push(itm.frg_challenge_sector_id);
							if (response.data.length == idx + 1) {
								resolve(tmp_arr);
							}
						});
					} else {
						resolve(tmp_arr);
					}
				});
			} else if (type == "provider") {
				this.http.post(this.api_url + "/challenge_sectors_and_providers/select_mul", data).subscribe((response: any) => {
					if (response.data.length) {
						_.each(response.data, (itm, idx) => {
							tmp_arr.push(itm.frg_challenge_sector_id);
							if (response.data.length == idx + 1) {
								resolve(tmp_arr);
							}
						});
					} else {
						resolve(tmp_arr);
					}
				});
			}
		});
	}
}
