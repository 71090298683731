import { Component, Input, OnInit, Output, EventEmitter, OnChanges } from "@angular/core";

@Component({
	selector: "pagination-controller",
	templateUrl: "./pagination.component.html",
	styleUrls: ["./pagination.component.css"],
})
export class PaginationComponent implements OnChanges {
	@Input() totalItems: number;
	@Input() pageSize: number = 10;
	@Output() onPageChange = new EventEmitter<number>();
	selectedPage: number;
	pagination: any = null;
	constructor() {}

	ngOnChanges() {
		this.selectedPage = 1;
		this.pagination = this.paginate(this.totalItems, this.selectedPage, this.pageSize);
	}

	goToPage(pageIndex) {
		this.selectedPage = pageIndex;
		this.pagination = this.paginate(this.totalItems, this.selectedPage, this.pageSize);
		this.onPageChange.emit(pageIndex);
		window.scroll(0, 0);
	}

	paginate(totalItems: number, currentPage: number, pageSize: number, maxPages: number = 10) {
		// calculate total pages
		let totalPages = Math.ceil(totalItems / pageSize);
		// ensure current page isn't out of range
		if (currentPage < 1) {
			currentPage = 1;
		} else if (currentPage > totalPages) {
			currentPage = totalPages;
		}
		let startPage: number, endPage: number;
		if (totalPages <= maxPages) {
			// total pages less than max so show all pages
			startPage = 1;
			endPage = totalPages;
		} else {
			// total pages more than max so calculate start and end pages
			let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
			let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
			if (currentPage <= maxPagesBeforeCurrentPage) {
				// current page near the start
				startPage = 1;
				endPage = maxPages;
			} else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
				// current page near the end
				startPage = totalPages - maxPages + 1;
				endPage = totalPages;
			} else {
				// current page somewhere in the middle
				startPage = currentPage - maxPagesBeforeCurrentPage;
				endPage = currentPage + maxPagesAfterCurrentPage;
			}
		}
		// calculate start and end item indexes
		let startIndex = (currentPage - 1) * pageSize;
		let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
		// create an array of pages to ng-repeat in the pager control
		let pages = Array.from(Array(endPage + 1 - startPage).keys()).map((i) => startPage + i);
		// return object with all pager properties required by the view
		return {
			totalItems: totalItems,
			currentPage: currentPage,
			pageSize: pageSize,
			totalPages: totalPages,
			startPage: startPage,
			endPage: endPage,
			startIndex: startIndex,
			endIndex: endIndex,
			pages: pages,
		};
	}
}
