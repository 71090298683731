import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import * as fuzzysort from "fuzzysort";

@Injectable({
  providedIn: "root",
})
export class HelpersService {
  logoSrc$: BehaviorSubject<any>;

  constructor() {
    this.logoSrc$ = new BehaviorSubject<String>(`<a class="" href="/" >
    <img class="app-logo" src="assets/img/cix-logo.png" alt="Logo" />
  </a>`);
  }

  sortBestMatches(search, targets) {
    const results: any = fuzzysort.go(search, targets);
    // console.log(results);
    return results;
  }

  getAppLogo() {
    return this.logoSrc$.asObservable();
  }

  setAppLogo(path) {
    this.logoSrc$.next(path);
  }
}
