import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-challenges-multi-select',
  templateUrl: './challenges-multi-select.component.html',
  styleUrls: ['./challenges-multi-select.component.css']
})
export class ChallengesMultiSelectComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
