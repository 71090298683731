import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { ImageCroppedEvent, base64ToFile } from "ngx-image-cropper";
import {environment} from "../../../../environments/environment"

@Component({
  selector: 'app-gallery-upload',
  templateUrl: './gallery-upload.component.html',
  styleUrls: ['./gallery-upload.component.css']
})
export class GalleryUploadComponent implements OnInit {

  @Input() gallery:any;
  @Output() onGalleryChange = new EventEmitter<any>();
  imageChangedEvent: any = "";
  croppedImage: any = "";
  fileLoaded: boolean;
  previewArr: string[] = [];
  galleryArr: any[] = [];
  serverUrl:string = environment.server_url;
  constructor() {}

  ngOnInit() {
    if(this.gallery){
      this.previewArr = this.gallery.map(e=>this.serverUrl+e);
      this.galleryArr = this.gallery;
    }
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded(image: HTMLImageElement) {
    this.fileLoaded = true;
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  removeImage(removeIndex) {
    if (~removeIndex) {
      this.galleryArr.splice(removeIndex, 1);
      this.previewArr.splice(removeIndex, 1);
      this.onGalleryChange.emit(this.galleryArr);
    }
  }

  saveEditedGalleryImage() {
    const fileBeforeCrop = this.imageChangedEvent.target.files[0];
    const croppedFile = new File(
      [base64ToFile(this.croppedImage)],
      fileBeforeCrop.name,
      {
        type: fileBeforeCrop.type,
      }
    );
    this.galleryArr.push(croppedFile);
    // console.log("cropped-file", croppedFile);
    this.previewArr.push(this.croppedImage);
    // console.log("croppedImage-previewArr", this.croppedImage);
    this.imageChangedEvent = null;
    this.fileLoaded = false;
    this.croppedImage = "";
    this.onGalleryChange.emit(this.galleryArr);
    document.getElementById("galleryModal").click();
  }

}
