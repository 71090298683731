import { AfterViewInit, AfterContentInit, OnChanges, AfterContentChecked, AfterViewChecked, ChangeDetectorRef, Component, ElementRef, Input, OnInit } from "@angular/core";

@Component({
	selector: "collapse-content",
	templateUrl: "./collapsable-content.component.html",
	styleUrls: ["./collapsable-content.component.css"],
})
export class CollapsableContentComponent implements OnInit, AfterViewInit {
	//the text that need to be put in the container
	@Input() text: string;
	//maximum height of the container
	@Input() maxHeight: number = 70;
	//set these to false to get the height of the expended container
	public isCollapsed: boolean = false;
	public isCollapsable: boolean = false;

	constructor(private elementRef: ElementRef, private cdRef: ChangeDetectorRef) {}
	ngOnInit() {}

	ngAfterViewInit() {
		let currentHeight = this.elementRef.nativeElement.getElementsByTagName("div")[0].offsetHeight;
		//collapsable only if the contents make container exceed the max height
		if (currentHeight > this.maxHeight) {
			this.isCollapsed = true;
			this.isCollapsable = true;
			this.cdRef.detectChanges();
		}
	}
}
