import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ImageCropperModule } from "ngx-image-cropper";
import { ImageCropperComponent } from "./image-cropper/image-cropper.component";
import { AvatarUploadComponent } from "./avatar-upload/avatar-upload.component";
import { GalleryUploadComponent } from "./gallery-upload/gallery-upload.component";
import { PaginationComponent } from "./pagination/pagination.component";
import { AddAchievementInnovatorComponent } from "./add-achievement-innovator/add-achievement-innovator.component";
import { AchievementItemComponent } from "./add-achievement-innovator/achievement-item/achievement-item.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CompanylogoUploadComponent } from "./companylogo-upload/companylogo-upload.component";
import { CollapsableContentComponent } from "./collapsable-content/collapsable-content.component";
import { TooltipComponent } from "./tooltip/tooltip.component";
import { DocUploadComponent } from "./doc-upload/doc-upload.component";
import { NotifierComponent } from "./notifier/notifier.component";

@NgModule({
	declarations: [
		ImageCropperComponent,
		AvatarUploadComponent,
		GalleryUploadComponent,
		PaginationComponent,
		AddAchievementInnovatorComponent,
		AchievementItemComponent,
		CompanylogoUploadComponent,
		CollapsableContentComponent,
		TooltipComponent,
		DocUploadComponent,
		NotifierComponent,
	],
	imports: [CommonModule, ImageCropperModule, FormsModule, ReactiveFormsModule],
	exports: [
		ImageCropperComponent,
		AvatarUploadComponent,
		GalleryUploadComponent,
		PaginationComponent,
		AddAchievementInnovatorComponent,
		CompanylogoUploadComponent,
		CollapsableContentComponent,
		TooltipComponent,
		DocUploadComponent,
		NotifierComponent,
	],
})
export class WidgetsModule {}
