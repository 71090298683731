import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import * as _ from "lodash";

@Component({
	selector: "add-achievement",
	templateUrl: "./add-achievement-innovator.component.html",
	styleUrls: ["./add-achievement-innovator.component.css"],
})
export class AddAchievementInnovatorComponent implements OnInit {
	@Input() achievements: any;
	@Output() onAchievementChange = new EventEmitter<any>();
	addAchievementsForm: FormGroup;
	isSubmitted: boolean;
	yearArr: any = _.range(1950, new Date().getFullYear() + 1);
	monthDropdown:string[] = []; 
	monthArr: string[] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
	achievementsArr: any = [];
	yearSelected:boolean = false;
	constructor(private toastr: ToastrService, private router: Router, private fb: FormBuilder) {}

	async ngOnInit() {
		this.addAchievementsForm = this.fb.group({
			month: ["", [Validators.required]],
			year: ["", [Validators.required]],
			title: ["", [Validators.required,Validators.minLength(45), Validators.maxLength(80)]],
		});
		if (this.achievements) {
			this.achievementsArr = this.achievements;
			// console.log(this.achievementsArr);
			this.onAchievementChange.emit(this.achievementsArr);
		}
	}

	get addAchievementFormContorl() {
		return this.addAchievementsForm.controls;
	}

	createAchievement() {
		this.isSubmitted = true;
		if (this.addAchievementsForm.valid) {
			this.achievementsArr.push(this.addAchievementsForm.value);
			this.onAchievementChange.emit(this.achievementsArr);
			this.isSubmitted = false;
			this.addAchievementsForm.reset();
			document.getElementById("addachievements").click();
		} else {
			// console.log("add achivement");
			this.toastr.error("Please provide the required values to proceed further", "Validation Error");
		}
	}

	handleYearSelect(){
		let currentYear = new Date().getFullYear();
		let currentMonth = new Date().getMonth();
		if(this.addAchievementsForm.controls.year.value){	
			if(this.addAchievementsForm.controls.year.value < currentYear){
				this.monthDropdown = this.monthArr;
			}else{
				this.addAchievementsForm.controls['month'].setValue('');
				this.monthDropdown = this.monthArr.slice(0,currentMonth+1);
			}
			this.yearSelected = true;		
		}else{
			this.yearSelected = false;
			this.addAchievementsForm.controls['month'].setValue('');
		}		
	}

	handleEditAchievement(editData) {
		let editIndex = editData.index;
		let editArr = this.achievementsArr;
		if (~editIndex) {
			editArr[editIndex] = editData.data;
			this.achievementsArr = editArr;
			this.onAchievementChange.emit(this.achievementsArr);
		}
	}

	handleDeleteAchievement(deleteIndex) {
		if (~deleteIndex) {
			this.achievementsArr.splice(deleteIndex, 1);
			this.onAchievementChange.emit(this.achievementsArr);
		}
	}
}
