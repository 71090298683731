import { LoggedInUser } from "../model/loggedinuser.model";

export class SetLoggedInUser {
	static readonly type = "LoggedInUser Add";
	constructor(public payload: LoggedInUser) {}
}

export class UpdateLoggedInUser {
	static readonly type = "LoggedInUser Update";
	constructor(public payload: LoggedInUser) {}
}

export class ClearLoggedInUserSession {
	static readonly type = "LoggedInUser Delete";
}

export class GetLoggedInUser {
	static readonly type = "LoggedInUser Get";
}
