import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { ImageCroppedEvent, base64ToFile } from "ngx-image-cropper";
import { environment } from "../../../../environments/environment";

@Component({
	selector: "companylogo-upload",
	templateUrl: "./companylogo-upload.component.html",
	styleUrls: ["./companylogo-upload.component.css"],
})
export class CompanylogoUploadComponent implements OnInit {
	// logo
	logoChangedEvent: any = "";
	croppedLogo: any = "";
	logoLoaded: boolean = false;
	logoSaved: boolean = false;
	@Input() logo: any;
	@Input() Src: string;
	@Input() error: boolean = false;
	@Output() onSaveLogo = new EventEmitter<File>();
	@Output() onRemoveLogo = new EventEmitter<File | string>();
	serverUrl: string = environment.server_url;
	constructor() {}

	ngOnInit() {
		if (this.logo) {
			this.Src = this.serverUrl + this.logo;
		}
	}

	logoChangeEvent(event: any): void {
		this.logoChangedEvent = event;
	}
	logoCropped(event: ImageCroppedEvent) {
		this.croppedLogo = event.base64;
	}
	logoLoadedEvent() {
		this.logoLoaded = true;
	}

	removeImage() {
		if (isBase64(this.Src)) {
			const fileBeforeCrop = this.logoChangedEvent.target.files[0];
			this.onRemoveLogo.emit(
				new File([base64ToFile(this.croppedLogo)], fileBeforeCrop.name, {
					type: fileBeforeCrop.type,
				})
			);
		} else {
			this.onRemoveLogo.emit(this.Src);
		}
		this.Src = null;
		this.croppedLogo = "";
		this.logoChangedEvent = null;
		this.logoLoaded = false;
	}

	saveCropedLogo() {
		const fileBeforeCrop = this.logoChangedEvent.target.files[0];
		this.Src = this.croppedLogo;
		this.onSaveLogo.emit(
			new File([base64ToFile(this.croppedLogo)], fileBeforeCrop.name, {
				type: fileBeforeCrop.type,
			})
		);
		this.logoSaved = true;
		document.getElementById("logoModal").click();
	}
}

function isBase64(str) {
	if (str === "" || str.trim() === "") {
		return false;
	}
	try {
		base64ToFile(str);
		return true;
	} catch (err) {
		return false;
	}
}
