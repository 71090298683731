import { Component, OnInit, ViewEncapsulation, OnDestroy } from "@angular/core";
import { HelpersService } from "../../services/helpers.service";
import { HomeService } from "../home.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit, OnDestroy {
  citiesCount: any;
  seekersCount: any;
  solutionsCount: any;
  challengeSectorCount: any;
  challengesCount: any;
  innovatorsCount: any;

  challengeSectors: any = [];

  hideLogo: boolean;

  smarcity: any = {
    city: 100,
    seekers: 100,
    solutions: 112,
    challenge_sectors: 10,
    challenges: 60,
  };
  search_data = {
    name: "Maharashtra",
    desc: "State with the highest GDP in India",
    solutions: 80,
    challenges: 95,
    seekers: 42,
    providers: 35,
    pilots: 0,
    challenge_sectors: 10,
  };
  banner_images = [
    {url: 'assets/img/home/frg_hmpg_5.jpg'},
    {url: 'assets/img/home/frg_hmpg_3.jpg'},
    {url: 'assets/img/home/frg_hmpg_1.jpg'}
  ]
  challenge_sectors = [
    {
      image_url: "assets/img/landingpage-s6-icon2.png",
      name: "Energy Management",
      desc:
        "Smart Energy Management is an integral part of urban transformation. The goal is to achieve sustainable and low-carbon urban development through renewable energy and digital governance.",
      solutions: 22,
      challenges: 18,
      pilots: 0,
      certified_solutons: 0,
    },
    {
      image_url: "assets/img/landingpage-s6-icon3.png",
      name: "Safety & Security",
      desc:
        "The quality of safety and security system in a city directly impacts the quality of life of the citizens. The goal is to enhance the safety and security of the city through intelligent and digital systems.",
      solutions: 22,
      challenges: 18,
      pilots: 0,
      certified_solutons: 0,
    },
    {
      image_url: "assets/img/landingpage-s6-icon4.png",
      name: "Healthcare",
      desc:
        "Cities constantly endeavour to improve the quality of life and the quality of healthcare services provided strongly influence the quality of life. The focus here would be on providing better healthcare services to the citizens through technology & innovation.",
      solutions: 22,
      challenges: 18,
      pilots: 0,
      certified_solutons: 0,
    },
    {
      image_url: "assets/img/landingpage-s6-icon5.png",
      name: "Urban Infra and Buildings",
      desc:
        "Urban infrastructure is one of the crucial elements that contribute to a city’s development. This sector focuses on enhancing the city’s existing infrastructure, both digital and physical infrastructure, through intelligent systems and technologies.",
      solutions: 22,
      challenges: 18,
      pilots: 0,
      certified_solutons: 0,
    },
    {
      image_url: "assets/img/landingpage-s6-icon6.png",
      name: "Governance",
      desc:
        "Smart Governance involves using technology to support planning and decision making in the city’s administration. It also involves the use of Digital Technologies in delivering various public services.",
      solutions: 22,
      challenges: 18,
      pilots: 0,
      certified_solutons: 0,
    },
    {
      image_url: "assets/img/landingpage-s6-icon7.png",
      name: "Education",
      desc:
        "Education is a fundamental driver of human development, from improving incomes to deepening democracy. The focus would be on providing support & resources to institutions, skill development, access to learning materials for citizens, etc.",
      solutions: 22,
      challenges: 18,
      pilots: 0,
      certified_solutons: 0,
    },
    {
      image_url: "assets/img/landingpage-s6-icon8.png",
      name: "Mobility Infrastructure",
      desc:
        "Rapid urbanisation puts a strain on the mobility infrastructure such as traffic congestion, pollution, poor road safety, and parking. This sector aims to make an efficient urban mobility infrastructure through smart technologies and better infrastructure planning.",
      solutions: 22,
      challenges: 18,
      pilots: 0,
      certified_solutons: 0,
    },
    {
      image_url: "assets/img/landingpage-s6-icon9.png",
      name: "Environment Management",
      desc:
        "Environmental Management is a critical factor that determines the sustainability of any city and also contributes to the quality of life in that city. This sector focuses on bringing innovative solutions to preserve the environment thus making the city sustainable.",
      solutions: 22,
      challenges: 18,
      pilots: 0,
      certified_solutons: 0,
    },
    {
      image_url: "assets/img/landingpage-s6-icon10.png",
      name: "Water Management",
      desc:
        "Efficient water management is a basic necessity of any city, and it involves planning, developing, distributing and managing the optimum use of water resources. The objective of this sector to efficiently manage the water resources of the entire city.",
      solutions: 22,
      challenges: 18,
      pilots: 0,
      certified_solutons: 0,
    },
    {
      image_url: "assets/img/landingpage-s6-icon11.png",
      name: "Waste Management",
      desc:
        "Waste management is an essential city service and is crucial to a city’s development due to rapid urbanization and growing population. The objective is to effectively manage the entire process from segregation, collection, distribution, to recycling.",
      solutions: 22,
      challenges: 18,
      pilots: 0,
      certified_solutons: 0,
    },
  ];

  constructor(private _hs: HomeService, private _hlps: HelpersService) {
    this._hlps
      .setAppLogo(`<a class="" href="http://mohua.gov.in/" target="_blank" >
    <img class="app-logo" src="assets/img/landingpage-hdr-logo.png" alt="Logo" />
  </a>`);
  }
  ngOnInit() {
    this.getAllCount();
    this.getChallengeSectors();
  }

  ngOnDestroy() {
    // switch logo
    this._hlps.setAppLogo(`<a class="" href="/" >
    <img class="app-logo" src="assets/img/cix-logo.png" alt="Logo" />
  </a>`);
  }

  getAllCount() {
    // CitiesCount
    this._hs.getCitiesCount().subscribe((resp: any) => {
      this.citiesCount = resp.data;
    });

    // Seekers
    this._hs.getSeekersCount().subscribe((resp: any) => {
      this.seekersCount = resp.data;
    });

    // Solutions
    this._hs.getSolutionsCount().subscribe((resp: any) => {
      this.solutionsCount = resp.data;
    });

    // ChallengeSectors
    this._hs.getChallengeSector().subscribe((resp: any) => {
      this.challengeSectorCount = resp.data;
    });

    // Challenges
    this._hs.getchallengesCount().subscribe((resp: any) => {
      this.challengesCount = resp.data;
    });

    // Innovators (Provider)
    this._hs.getProvidersCount().subscribe((resp: any) => {
      this.innovatorsCount = resp.data;
    });
  }

  getChallengeSectors() {
    this._hs.getChallengeSectors().subscribe((resp: any) => {
      resp.data.forEach((el) => {
        let challengesCount = el.challenges.length;
        let solutionsCount = el.solutions.length;
        this.challenge_sectors.forEach((element) => {
          if (element.name.toLowerCase() == el.name.toLowerCase()) {
            element.solutions = solutionsCount;
            element.challenges = challengesCount;
          }
        });
        // this.challengeSectors.push(
        //   el['name'] = {
        //     name: el.name,
        //     challengesCount: challengesCount,
        //     solutionsCount: solutionsCount
        //   }
        // )
      });
    });
  }
}
