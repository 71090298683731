import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WidgetsModule } from "../shared/widgets/widgets.module";
import { FooterComponent } from "./footer/footer.component";
import { HeaderComponent } from "./header/header.component";
import { RouterModule } from "@angular/router";

@NgModule({
	declarations: [FooterComponent, HeaderComponent],
	imports: [CommonModule, WidgetsModule, RouterModule],
	exports: [FooterComponent, HeaderComponent],
})
export class PagesPartialModule {}
