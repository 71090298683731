import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HelpService {

  api_url = environment.api_url;

  constructor(private http: HttpClient) { }

  submitContactFormAPI(post_data:any){
    return this.http.post(this.api_url + '/users/submit_contact_form',post_data)
  }
}
