import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {HelpersService} from '../services/helpers.service'

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  api_url = environment.api_url;
  constructor(private http: HttpClient,private _hs:HelpersService) { 
    this._hs.setAppLogo('assets/imglandingpage-hdr-logo.png');
  }

  getCitiesCount() {
    return this.http.post(this.api_url + '/smart_cities/count', null);
  }
  getSeekersCount() {
    return this.http.post(this.api_url + '/seekers/count', null);
  } 
  getSolutionsCount() {
    return this.http.post(this.api_url + '/solutions/count', null);
  } 
  getChallengeSector() {
    return this.http.post(this.api_url + '/challenge_sectors/count', null);
  } 
  getchallengesCount() {
    return this.http.post(this.api_url + '/challenges/count', null);
  }
  getChallengeSectors(){
    return this.http.post(this.api_url + '/challenge_sectors/select_mul', null);
  }
  getProvidersCount(){
    return this.http.post(this.api_url + '/providers/count', null);
  }
}
