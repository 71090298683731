import { Injectable } from "@angular/core";
import { ValidatorFn, AbstractControl } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import jwt_decode from "jwt-decode";
import { Select } from "@ngxs/store";
import { LoggedInUserState, LoggedInUserStateModel } from "../state/loggedinuser.state";
import { LoggedInUser } from "../state/model/loggedinuser.model";
import { Observable } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class CustomvalidationService {
	api_url = environment.api_url;
	@Select(LoggedInUserState) loggedInUser$: Observable<LoggedInUserStateModel>;
	loggedInUser: LoggedInUser;
	constructor(private http: HttpClient) {
		this.loggedInUser$.subscribe((resp) => {
			this.loggedInUser = resp.LoggedInUser;
		});
	}

	MatchPassword(password: string, confirmPassword: string) {
		return (formGroup: FormGroup) => {
			const passwordControl = formGroup.controls[password];
			const confirmPasswordControl = formGroup.controls[confirmPassword];

			if (!passwordControl || !confirmPasswordControl) {
				return null;
			}

			if (confirmPasswordControl.errors && !confirmPasswordControl.errors.passwordMismatch) {
				return null;
			}

			if (passwordControl.value !== confirmPasswordControl.value) {
				confirmPasswordControl.setErrors({ passwordMismatch: true });
			} else {
				confirmPasswordControl.setErrors(null);
			}
		};
	}
	comparePassword(old_password: string, password: string) {
		return (formGroup: FormGroup) => {
			const oldPasswordControl = formGroup.controls[old_password];
			const passwordControl = formGroup.controls[password];

			if (!oldPasswordControl || !passwordControl) {
				return null;
			}

			if (oldPasswordControl.errors && !passwordControl.errors.passwordSame) {
				return null;
			}

			if (oldPasswordControl.value == passwordControl.value) {
				passwordControl.setErrors({ passwordSame: true });
			} else {
				passwordControl.setErrors(null);
			}
		};
	}

	emailAvailabilityValidator(userControl: AbstractControl) {
		return new Promise((resolve) => {
			const post_data = { filter: { email: userControl.value } };
			const currentUserID = this.loggedInUser.id;
			this.http.post(this.api_url + "/users/select_mul", post_data).subscribe((response: any) => {
				let isCurrentUserEmail = response.data.some((user) => {
					if (currentUserID == user.id) {
						return true;
					}
				});
				setTimeout(() => {
					if (response.status === true && response.data.length > 0) {
						isCurrentUserEmail ? resolve(null) : resolve({ emailAlreadyExist: true });
					} else {
						resolve(null);
					}
				}, 200);
			});
		});
	}

	phoneAvailabilityValidator(userControl: AbstractControl) {
		return new Promise((resolve) => {
			const post_data = { filter: { mobile: userControl.value } };
			const currentUserID = this.loggedInUser.id;
			this.http.post(this.api_url + "/users/select_mul", post_data).subscribe((response: any) => {
				let isCurrentUserphonenum = response.data.some((user) => {
					if (currentUserID == user.id) {
						return true;
					}
				});
				setTimeout(() => {
					if (response.status === true && response.data.length > 0) {
						isCurrentUserphonenum ? resolve(null) : resolve({ phonenumAlreadyExist: true });
					} else {
						resolve(null);
					}
				}, 200);
			});
		});
	}



	checkRegisteredEmail(userControl: AbstractControl) {
		return new Promise((resolve) => {
			const postData = { filter: { email: userControl.value } };
			this.http.post(this.api_url + "/users/select_mul", postData).subscribe((response: any) => {
				setTimeout(() => {
					if (response.status === true && response.data.length > 0) {
						resolve(null);
					} else {
						resolve({ emailNotRegistered: true });
					}
				}, 200);
			});
		});
	}

	checkForDuplicateChallenges(userControl: AbstractControl) {
		return new Promise((resolve) => {
			const postData = { filter: { name: userControl.value.trim() } };
			this.http.post(this.api_url + "/challenges/select_mul", postData).subscribe((response: any) => {
				if (response.status === false && response.data.length <= 0) {
					resolve(null);
				} else {
					resolve({ challengeAlreadyExist: true });
				}
			});
		});
	}
	validateDate(startDate: string, closeDate: string) {
		return (formGroup: FormGroup) => {
			const closeControl = formGroup.controls[closeDate];
			const startControl = formGroup.controls[startDate];
	
			console.log(closeControl, startControl)
			if (new Date(closeControl.value) <= new Date(startControl.value)) {
				closeControl.setErrors({ dateError: true });
			} else {
				closeControl.setErrors(null);
			}
		};
	}
	// compareTwoDates() {
	// 	if (new Date(this.editChallengeForm.Step2.controls['closeDate'].value) <= new Date(this.editChallengeForm.Step2.controls['startDate'].value)) {

	// 		let error = { isError: true, errorMessage: "End Date can't  before start date" }
	// 		console.log("error", error);
	// 	}
	// 	else {
	// 		console.log("No date error");
	// 	}
	
	// }
}
