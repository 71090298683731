import { Component, Renderer2, OnInit, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { Validators, FormGroup, FormBuilder } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { LoginService } from "./login.service";
import { environment } from "../../../environments/environment";
import { Store } from "@ngxs/store";
import { SetLoggedInUser } from "../../state/action/loggedinuser.action";
import { LoggedInUser } from "../../state/model/loggedinuser.model";
import { Observable } from "rxjs";
import { AuthService } from "../../services/auth.service";
@Component({
	selector: "app-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
	currentUser$: Observable<LoggedInUser>;
	currentUserData: LoggedInUser;

	server_url = environment.server_url;
	login_frm: any = {};
	frm_valid_msg: boolean;
	invalid_creds: boolean;
	loginForm: FormGroup;
	isSubmitted: boolean = false;
	isLoading: boolean = false;
	passwordField: boolean;
	loggedInUser: any;
	urlParams: any = null;
	constructor(
		private _ls: LoginService,
		private router: Router,
		private toastr: ToastrService,
		private fb: FormBuilder,
		private _renderer2: Renderer2,
		private activatedRoute: ActivatedRoute,
		@Inject(DOCUMENT) private _document: Document,
		private store: Store,
		private auth: AuthService
	) {
		this.activatedRoute.queryParams.subscribe((params) => {
			this.urlParams = params;
		});
		this.loginForm = this.fb.group({
			email: ["", [Validators.required, Validators.email]],
			password: ["", [Validators.required]],
		});
	}

	ngOnInit() {
		// loading startup india auth script
		const authScript = this._renderer2.createElement("script");
		authScript.type = `text/javascript`;
		authScript.src = "https://www.startupindia.gov.in/etc/designs/invest-india/investindialibs/js/siauthlogin.js";
		authScript.innerHTML = "console.log('auth script loaded')";
		this._renderer2.appendChild(this._document.body, authScript);
	}

	get loginFormControl() {
		return this.loginForm.controls;
	}

	async logIn(form) {
		try {
			this.isSubmitted = true;
			if (this.loginForm.valid) {
				this.isLoading = true;
				let response: any = await this.auth.login({
					email: this.loginForm.value.email,
					password: this.loginForm.value.password,
				});
				this.isLoading = false;
				if (response.status == true) {
					if (response.data.role_seeker) {
						// setting the loggedin user state(seeker)
						this.store.dispatch(
							new SetLoggedInUser({
								id: response.data.id,
								email: response.data.email,
								name: response.data.seeker[0].fullname,
								userType: "seeker",
								userTypeId: response.data.seeker[0].id,
								avatar: `${this.server_url}` + response.data.seeker[0].avatar,
								slug: response.data.seeker[0].slug,
								isLoggedIn: true,
							})
						);
						this.loginSuccess(form, "seeker", response);
					} else if (response.data.role_provider) {
						// setting the loggedin user state(innovator)
						this.store.dispatch(
							new SetLoggedInUser({
								id: response.data.id,
								email: response.data.email,
								name: response.data.provider[0].fullname,
								userType: "provider",
								userTypeId: response.data.provider[0].id,
								avatar: `${this.server_url}` + response.data.provider[0].avatar,
								slug: response.data.provider[0].slug,
								isLoggedIn: true,
							})
						);
						this.loginSuccess(form, "provider", response);
					} else if (response.data.role_cwg) {
						// localStorage.setItem('user_type', 'cwg')
					}
				} else {
					if (response.status == false && response.msg == "Invalid Email") {
						this.loginForm.controls["email"].setErrors({ incorrect: true });
					} else if (response.status == false && response.msg == "Invalid Password") {
						this.loginForm.controls["password"].setErrors({ incorrect: true });
					} else if (response.status == false && response.msg == "Account not activated") {
						this.isSubmitted = false;
						form.resetForm();
						this.toastr.warning("", "Account not activated yet");
					} else if (response.status == false && response.msg == "Approval pending") {
						this.isSubmitted = false;
						form.resetForm();
						this.toastr.warning("Your account is being reviewed by the Admin and is pending approval. You will receive an email once it is approved.", "Pending for Approval");
					}
				}
			} else {
				this.toastr.error("", "Please provide the required fields to login");
			}
		} catch (err) {
			console.log(err);
			this.isLoading = false;
			this.toastr.error("", "Something went wrong please try again");
		}
	}

	loginSuccess(loginForm: any, userType: string, loginResponse: any) {
		this.isSubmitted = false;
		loginForm.resetForm();
		this.toastr.success("", "Login successful");
		var post_data = {};
		if (this.urlParams.redirectURL) {
			this.router.navigate([this.urlParams.redirectURL]);
		} else {
			switch (userType) {
				case "seeker": {
					post_data = {
						filter: {
							frg_seeker_id: loginResponse.data.seeker[0].id,
						},
					};
					this._ls.getSelectedSectorListAPI("seeker", post_data).subscribe((response: any) => {
						if (response.status == true && response.data.length) {
							this.router.navigate(["/discover/providers"]);
						} else {
							this.router.navigate(["/discover/select-sector"]);
						}
					});
					break;
				}
				case "provider": {
					post_data = {
						filter: {
							frg_provider_id: loginResponse.data.provider[0].id,
						},
					};
					this._ls.getSelectedSectorListAPI("provider", post_data).subscribe((response: any) => {
						if (response.status == true && response.data.length) {
							this.router.navigate(["/discover/challenges"]);
						} else {
							this.router.navigate(["/discover/select-sector"]);
						}
					});
					break;
				}
				default: {
					// this.router.navigate(['/discover/challenges']);
					break;
				}
			}
		}
	}

	togglePassword() {
		this.passwordField = !this.passwordField;
	}
}
