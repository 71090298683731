import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Validators, FormGroup, FormBuilder } from "@angular/forms";
import { CustomvalidationService } from "../../services/customvalidation.service";
import { ResetPasswordService } from "./reset-password.service";

@Component({
	selector: "app-forgot-password",
	templateUrl: "./forgot-password.component.html",
	styleUrls: ["./forgot-password.component.css"],
})
export class ForgotPasswordComponent implements OnInit {
	forgotPasswordStep1: boolean;
	forgotPasswordStep2: boolean;
	forgotPasswordStep3: boolean;
	ForgotPassword: any = {
		Step1: FormGroup,
		Step2: FormGroup,
		Step3: FormGroup,
	};
	isSubmitted: any = {
		Step1: false,
		Step2: false,
		Step3: false,
	};

	step1Loading: boolean = false;
	step2Loading: boolean = false;
	step3Loading: boolean = false;
	resendCodeLoading: boolean = false;

	constructor(
		private toastr: ToastrService,
		private router: Router,
		private fb: FormBuilder,
		private customValidator: CustomvalidationService,
		private _reset: ResetPasswordService
	) {}

	ngOnInit() {
		this.forgotPasswordStep1 = false;
		this.forgotPasswordStep2 = true;
		this.forgotPasswordStep3 = true;

		this.ForgotPassword = {
			Step1: this.fb.group({
				email: ["", [Validators.required, Validators.email], this.customValidator.checkRegisteredEmail.bind(this.customValidator)],
			}),
			Step2: this.fb.group({
				verificationCode: ["", [Validators.required]],
			}),
			Step3: this.fb.group(
				{
					password: ["", [Validators.required, Validators.pattern("^(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$")]],
					confirmPassword: ["", [Validators.required]],
				},
				{ validator: this.customValidator.MatchPassword("password", "confirmPassword") }
			),
		};
	}

	get formControlStep1() {
		return this.ForgotPassword.Step1.controls;
	}
	get formControlStep2() {
		return this.ForgotPassword.Step2.controls;
	}
	get formControlStep3() {
		return this.ForgotPassword.Step3.controls;
	}

	gotoStep(step: any) {
		switch (step) {
			case "step1": {
				this.forgotPasswordStep1 = false;
				this.forgotPasswordStep2 = true;
				this.forgotPasswordStep3 = true;
				break;
			}
			case "step2": {
				this.forgotPasswordStep1 = true;
				this.forgotPasswordStep2 = false;
				this.forgotPasswordStep3 = true;
				break;
			}
			case "step3": {
				this.forgotPasswordStep1 = true;
				this.forgotPasswordStep2 = true;
				this.forgotPasswordStep3 = false;
				break;
			}
		}
	}

	ShowNextStep(step: any) {
		if (step == "step2") {
			this.forgotPasswordStep1 = true;
			this.forgotPasswordStep2 = false;
			this.forgotPasswordStep3 = true;
		} else if (step == "step3") {
			this.forgotPasswordStep1 = true;
			this.forgotPasswordStep2 = true;
			this.forgotPasswordStep3 = false;
		}
	}

	goBack(step) {
		if (step == "step1") {
			this.forgotPasswordStep1 = false;
			this.forgotPasswordStep2 = true;
			this.forgotPasswordStep3 = true;
		}
	}

	async sendCode(form) {
		this.isSubmitted.Step1 = true;
		if (this.ForgotPassword.Step1.valid) {
			this.step1Loading = true;
			try {
				let resp: any = await this._reset.sendVerificationCode({
					data: {
						email: this.ForgotPassword.Step1.value.email,
					},
				});
				this.step1Loading = false;
				if (resp.status == true) {
					this.toastr.info("", resp.msg);
					this.ShowNextStep("step2");
				} else {
					this.toastr.error("", resp.msg);
				}
			} catch (err) {
				this.step1Loading = false;
				this.toastr.error("", "Something went wrong please try again");
			}
		} else {
			this.toastr.error("", "Please provide the registered email to proceed further");
		}
	}

	async verifyCode(form) {
		this.isSubmitted.Step2 = true;
		if (this.ForgotPassword.Step2.valid) {
			this.step2Loading = true;
			try {
				let resp: any = await this._reset.verifyResetCode({
					data: {
						email: this.ForgotPassword.Step1.value.email,
						verification_code: this.ForgotPassword.Step2.value.verificationCode,
					},
				});
				this.step2Loading = false;
				if (resp.status == true) {
					this.toastr.info("", resp.msg);
					this.ShowNextStep("step3");
				} else {
					this.toastr.error("", resp.msg);
				}
			} catch (err) {
				this.step2Loading = false;
				this.toastr.error("", "Something went wrong please try again");
			}
		} else {
			this.toastr.error("", "Please provide the verfication code to proceed further");
		}
	}

	async resetPassword(form) {
		this.isSubmitted.Step3 = true;
		if (this.ForgotPassword.Step3.valid) {
			this.step3Loading = true;
			try {
				let resp: any = await this._reset.resetPassword({
					data: {
						email: this.ForgotPassword.Step1.value.email,
						verification_code: this.ForgotPassword.Step2.value.verificationCode,
						new_password: this.ForgotPassword.Step3.value.password,
					},
				});
				this.step3Loading = false;
				if (resp.status == true) {
					this.toastr.success("", resp.msg);
					this.router.navigate(["/login"]);
				} else {
					this.toastr.error("", resp.msg);
				}
			} catch (err) {
				this.step3Loading = false;
				this.toastr.error("", "Something went wrong please try again");
			}
		} else {
			this.toastr.error("", "Please provide the required values to proceed further");
		}
	}

	async resendCode() {
		try {
			this.resendCodeLoading = true;
			let resp: any = await this._reset.sendVerificationCode({
				data: {
					email: this.ForgotPassword.Step1.value.email,
				},
			});
			this.resendCodeLoading = false;
			if (resp.status == true) {
				this.toastr.info("", resp.msg);
			} else {
				this.toastr.error("", resp.msg);
			}
		} catch (err) {
			this.resendCodeLoading = false;
			this.toastr.error("", "Something went wrong please try again");
		}
	}

	onSubmit(formIndex: string) {
		switch (formIndex) {
			case "Step1": {
				this.isSubmitted.Step1 = true;
				break;
			}
			case "Step2": {
				this.isSubmitted.Step2 = true;
				break;
			}
			case "Step3": {
				this.isSubmitted.Step3 = true;
				break;
			}
		}
	}
}
