// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	api_url: "https://apicityinx.niua.org/api/v1",
	server_url: "https://apicityinx.niua.org",
	firebase:{
		apiKey: "AIzaSyASEvyrTLYGC2eh29R5s7gHWFYr_-CN1yE",
		authDomain: "cityinx-forge.firebaseapp.com",
		projectId: "cityinx-forge",
		storageBucket: "cityinx-forge.appspot.com",
		messagingSenderId: "943041979938",
		appId: "1:943041979938:web:1d94b7317f0cab37270c7b",
	}
};


// staging

// export const environment = {
// 	production: false,
// 	api_url: "http://localhost:1337/api/v1",
// 	server_url: "http://localhost:1337",
// 	firebase: {
// 		apiKey: "AIzaSyA6YmjtvaOWvE3FERZgylFbnCmEOkFUoiI",
// 		authDomain: "cityinx-stage.firebaseapp.com",
// 		projectId: "cityinx-stage",
// 		storageBucket: "cityinx-stage.appspot.com",
// 		messagingSenderId: "267900392266",
// 		appId: "1:267900392266:web:4de9d3fd1a00bb933927b3"
// 	},

// };



// production

// export const environment = {
// 	production: false,
// 	api_url: "https://apicityinx.niua.org/api/v1",
// 	server_url: "https://apicityinx.niua.org",
// 	firebase:{
// 		apiKey: "AIzaSyASEvyrTLYGC2eh29R5s7gHWFYr_-CN1yE",
// 		authDomain: "cityinx-forge.firebaseapp.com",
// 		projectId: "cityinx-forge",
// 		storageBucket: "cityinx-forge.appspot.com",
// 		messagingSenderId: "943041979938",
// 		appId: "1:943041979938:web:1d94b7317f0cab37270c7b",
// 	}
// };



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
