import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { Observable } from "rxjs";
import { Select } from "@ngxs/store";
import { LoggedInUserState, LoggedInUserStateModel } from "./state/loggedinuser.state";

@Injectable({
	providedIn: "root",
})
export class AuthGuard implements CanActivate {
	redirectUrl: string;
	@Select(LoggedInUserState) loggedInUser$: Observable<LoggedInUserStateModel>;
	isLoggedIn: boolean;
	constructor(private _router: Router) {
		this.loggedInUser$.subscribe((resp) => {
			this.isLoggedIn = resp.LoggedInUser.isLoggedIn;
		});
	}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		let url: string = state.url;
		return this.checkLogin(url);
	}

	checkLogin(url: string): boolean {
		if (this.isLoggedIn) {
			return true;
		}
		// Store the attempted URL for redirecting
		this.redirectUrl = url;
		// Navigate to the login page
		this._router.navigate(["/login"]);
		return false;
	}
}
