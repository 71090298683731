import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CitystatsServiceService {
  api_url = environment.api_url;

  constructor(private http: HttpClient) { }

  async getCityInformation(cityName: string) {
    return await this.http.post(this.api_url + "/smart_cities/select_mul", {
      search: {
        name: cityName
      }
    }).toPromise();
  }

  async getSearchSuggestions(keyword: string) {
    return await this.http.post(this.api_url + '/smart_cities/select_mul', {
      search: {
        name: { contains: keyword }
      }
    }).toPromise();
  }


}
