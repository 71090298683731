import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import * as _ from "lodash";

@Component({
	selector: "app-achievement-item",
	templateUrl: "./achievement-item.component.html",
	styleUrls: ["./achievement-item.component.css"],
})
export class AchievementItemComponent implements OnInit {
	@Input() id: number;
	@Input() achievementData: any;
	@Output() onDelete = new EventEmitter<number>();
	@Output() onEdit = new EventEmitter<any>();

	editAchievementsForm: FormGroup;
	isSubmitted: boolean;
	yearArr: any = _.range(1950, new Date().getFullYear() + 1);
	monthArr: any = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
	achievementsArr: any = [];
	yearSelected:boolean = false;
	monthDropdown:string[] = [];

	constructor(private toastr: ToastrService, private router: Router, private fb: FormBuilder) {}

	ngOnInit() {
		this.editAchievementsForm = this.fb.group({
			month: ["", [Validators.required]],
			year: ["", [Validators.required]],
			title: ["", [Validators.required,Validators.minLength(45), Validators.maxLength(80)]],
		});
		this.patchEditForm();
		this.yearSelected = true;
		if(this.editAchievementsForm.controls.year.value){
			this.monthDropdown = this.monthArr;
		}
	}
	get editAchievementFormContorl() {
		return this.editAchievementsForm.controls;
	}

	patchEditForm() {
		this.editAchievementsForm.patchValue({
			month: this.achievementData.month,
			year: this.achievementData.year,
			title: this.achievementData.title,
			description: this.achievementData.description,
		});
	}

	handleYearSelect(){
		let currentYear = new Date().getFullYear();
		let currentMonth = new Date().getMonth();
		if(this.editAchievementsForm.controls.year.value){	
			if(this.editAchievementsForm.controls.year.value < currentYear){
				this.monthDropdown = this.monthArr;
			}else{
				this.editAchievementsForm.controls['month'].setValue('');
				this.monthDropdown = this.monthArr.slice(0,currentMonth+1);
			}
			this.yearSelected = true;		
		}else{
			this.yearSelected = false;
			this.editAchievementsForm.controls['month'].setValue('');
		}		
	}

	editAchievement() {
		this.isSubmitted = true;

		if (this.editAchievementsForm.valid) {
			this.isSubmitted = false;
			this.onEdit.emit({
				data: this.editAchievementsForm.value,
				index: this.id,
			});
			document.getElementById(`editachievements${this.id}`).click();
		} else {
			// console.log("add achivement item");
			this.toastr.error("Please provide the required values to proceed further", "Validation Error");
		}
	}

	emitDeleteIndex() {
		this.onDelete.emit(this.id);
	}
}
