import { Component, OnInit } from '@angular/core';
import { CitystatsServiceService } from "./citystats-service.service"
@Component({
  selector: 'city-stats',
  templateUrl: './city-stats.component.html',
  styleUrls: ['./city-stats.component.css']
})
export class CityStatsComponent implements OnInit {

  cityName: string = 'bengaluru'; //by default bengaluru will be displayed
  cityData: any;
  autoSuggestions: any;
  cityKeyWord:string;
  loading:boolean;
  constructor(private _city: CitystatsServiceService) { }

  async ngOnInit() {
    await this.fetchCityData(this.cityName);
  }

  async fetchCityData(name: string) {
    try {
      this.loading = true;
      let cityResp:any = await this._city.getCityInformation(name);
      this.cityData = cityResp.data[0]
      this.loading = false;
    } catch (err) {
      console.log(err)
    }
  }


  searchCity() {
    if(this.cityKeyWord.length == 0){
      this.fetchCityData(this.cityName);
    }else{
      this.fetchCityData(this.cityKeyWord);
    }
    this.autoSuggestions = null;   
  }

 async autoComplete(keyword){
    if(keyword.length > 3){
      this.autoSuggestions = await this._city.getSearchSuggestions(keyword);
    }else{
      this.autoSuggestions = null;
    }
  }

  searchAutoSuggestion(keyword){    
    this.cityKeyWord = keyword;
    this.autoSuggestions = null;
    this.fetchCityData(this.cityKeyWord);
  }
  
}
