import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from "@angular/common/http";
import { Observable, BehaviorSubject, throwError } from "rxjs";
import { catchError, filter, switchMap, take } from "rxjs/operators";
import { AuthService } from "./services/auth.service";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
	private isRefreshing = false;
	private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	constructor(private auth: AuthService, private toastr: ToastrService) {}
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const token = localStorage.getItem("access_token") || null;

		if (token) {
			request = request.clone({
				setHeaders: {
					Authorization: `Bearer ${token}`,
				},
			});
		}
		return next.handle(request).pipe(
			catchError((err) => {
				// prevent refresh token for these routes
				if (request.url.includes("login") || request.url.includes("refresh_token")) {
					if (request.url.includes("refresh_token")) {
						this.toastr.warning("", "Session expired!");
						this.auth.logout();
						return;
					}
					return throwError(err);
				}
				// throw other errors
				if (err instanceof HttpErrorResponse && err.status != 401) {
					return throwError(err);
				}
				// handle 401 errors
				if (!this.isRefreshing) {
					this.isRefreshing = true;
					this.refreshTokenSubject.next(null);
					return this.auth.refreshToken().pipe(
						switchMap((token: any) => {
							this.isRefreshing = false;
							this.refreshTokenSubject.next(token.access_token);
							return next.handle(this.addToken(request, token.access_token));
						})
					);
				} else {
					return this.refreshTokenSubject.pipe(
						filter((token) => token != null),
						take(1),
						switchMap((token: any) => {
							return next.handle(this.addToken(request, token));
						})
					);
				}
			})
		);
	}

	addToken(request: HttpRequest<any>, token: string) {
		return request.clone({
			setHeaders: {
				Authorization: `Bearer ${token}`,
			},
		});
	}
}
