import { State, Action, StateContext, Selector } from "@ngxs/store";
import { LoggedInUser } from "./model/loggedinuser.model";
import { GetLoggedInUser, SetLoggedInUser, UpdateLoggedInUser, ClearLoggedInUserSession } from "./action/loggedinuser.action";

export class LoggedInUserStateModel {
	LoggedInUser: LoggedInUser;
}

@State<LoggedInUserStateModel>({
	name: "loggedinuser",
	defaults: {
		LoggedInUser: {
			id: null,
			email: null,
			name: null,
			userType: null,
			userTypeId: null,
			avatar: null,
			slug: null,
			isLoggedIn: false,
		},
	},
})
export class LoggedInUserState {
	@Selector()
	static getLoggedInUserInfo(state: LoggedInUserStateModel) {
		return state.LoggedInUser;
	}

	@Action(SetLoggedInUser)
	setLoggedInUser({ setState }: StateContext<LoggedInUserStateModel>, { payload }: SetLoggedInUser) {
		setState({
			LoggedInUser: payload,
		});
	}

	@Action(UpdateLoggedInUser)
	updateUser({ setState }: StateContext<LoggedInUserStateModel>, { payload }: UpdateLoggedInUser) {
		setState({
			LoggedInUser: payload,
		});
	}

	@Action(ClearLoggedInUserSession)
	logOutUserSession(context: StateContext<LoggedInUserStateModel>) {
		context.setState({
			LoggedInUser: {
				id: null,
				email: null,
				name: null,
				userType: null,
				userTypeId: null,
				avatar: null,
				slug: null,
				isLoggedIn: false,
			},
		});
	}
}
