import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Component({
	selector: "doc-upload",
	templateUrl: "./doc-upload.component.html",
	styleUrls: ["./doc-upload.component.css"],
})
export class DocUploadComponent implements OnInit {
	@Input() uploadName: string;
	@Input() docs: any;
	@Output() onUploadChange = new EventEmitter<any>();
	fileNameArr: string[] = [];
	fileArr: any[] = [];
	serverUrl: string = environment.server_url;
	constructor() {}

	ngOnInit() {
		if (this.docs) {
			this.fileNameArr = this.docs.map((d) => this.extractFileName(d));
			this.fileArr = this.docs;
			console.log(this.docs);
		}
	}

	fileChangeEvent(uploadedFile: File[]) {
		if (uploadedFile.length) {
			this.fileArr.push(uploadedFile[0]);
			this.fileNameArr.push(uploadedFile[0].name);
			this.onUploadChange.emit(this.fileArr);
		}
	}

	removeFile(removeAt: number) {
		if (~removeAt) {
			this.fileArr.splice(removeAt, 1);
			this.fileNameArr.splice(removeAt, 1);
			this.onUploadChange.emit(this.fileArr);
		}
	}

	extractFileName(path: string) {
		let filename = path.replace(/^.*[\\\/]/, "");
		return filename;
	}
}
