import { Component, OnInit, Input } from "@angular/core";
import { ImageCroppedEvent, base64ToFile } from "ngx-image-cropper";

@Component({
  selector: "Image-Editor",
  templateUrl: "./image-cropper.component.html",
  styleUrls: ["./image-cropper.component.css"],
})
export class ImageCropperComponent implements OnInit {
  imageChangedEvent: any = "";
  croppedImage: any = "";
  fileLoaded: boolean;
  defaultConfig: any = {
    aspectRatio: "1 / 1",
    format: "png",
    type: "normal",
    resizeWidth: 512,
  };
  @Input() config: any;
  constructor() {
    this.config = { ...this.defaultConfig, ...this.config };
  }
  ngOnInit() {
    // console.log(this.config);
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    // console.log(event);
    this.croppedImage = event.base64;
  }
  imageLoaded(image: HTMLImageElement) {
    this.fileLoaded = true;
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
}
