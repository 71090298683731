import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { HelpersService } from "../../services/helpers.service";
import { RouteCache } from "../../route-cache";
import { Select, Store } from "@ngxs/store";
import { LoggedInUserState, LoggedInUserStateModel } from "../../state/loggedinuser.state";
import { LoggedInUser } from "../../state/model/loggedinuser.model";
import { Observable } from "rxjs";
import { AuthService } from "../../services/auth.service";

@Component({
	selector: "app-header",
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.css"],
	providers: [RouteCache],
})
export class HeaderComponent implements OnInit {
	is_loggedin: boolean;
	token: any;
	logo$: any;
	hideLogin: boolean = false;
	currentUser$: Observable<LoggedInUser>;
	currentUserData: LoggedInUser;
	notifications: any;
	@Select(LoggedInUserState) loggedInUser$: Observable<LoggedInUserStateModel>;

	constructor(private router: Router, private _hs: HelpersService, private _rcache: RouteCache, private _store: Store, private auth: AuthService) {
		this._hs.getAppLogo().subscribe((resp) => {
			this.logo$ = resp;
		});
		this.router.events.subscribe((url) => {
			if (this.router.url === "/login") {
				this.hideLogin = true;
			} else {
				this.hideLogin = false;
			}
		});
		this.loggedInUser$.subscribe((resp) => {
			this.currentUserData = resp.LoggedInUser;
		});
	}

	ngOnInit() {}

	logout() {
		this.auth.logout();
	}
}
