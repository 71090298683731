import { BrowserModule } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { RouteReuseStrategy } from "@angular/router";
import { AngularFireModule } from "@angular/fire";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { RouteCache } from "./route-cache";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./users/login/login.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { ChallengesMultiSelectComponent } from "./challenge/challenges-multi-select/challenges-multi-select.component";
import { ImageCropperModule } from "ngx-image-cropper";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { HomeComponent } from "./pages/home/home.component";
import { HelpComponent } from "./pages/help/help.component";
import { CityStatsComponent } from "./pages/city-stats/city-stats.component";
import { IvyCarouselModule } from "angular-responsive-carousel";
import { ForgotPasswordComponent } from "./users/forgot-password/forgot-password.component";
import { SelectChallengeSectorComponent } from "./users/select-challenge-sector/select-challenge-sector.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { NgxPageScrollModule } from "ngx-page-scroll";
import { NgxsModule } from "@ngxs/store";
import { LoggedInUserState } from "./state/loggedinuser.state";
import { AppinitService } from "./services/appinit.service";
import { environment } from "../environments/environment";
import { PagesPartialModule } from "./pages-partial/pages-partial.module";
// Interceptors
import { JwtInterceptor } from "./token.interceptor";
import { AngularFirestore } from "@angular/fire/firestore";
import { MomentModule } from 'ngx-moment';

//app startup functions
export function runAtAppInit1(initService: AppinitService) {
	return () => initService.runAtAppStartUp();
}


@NgModule({
	declarations: [AppComponent, LoginComponent, ChallengesMultiSelectComponent, HomeComponent, HelpComponent, ForgotPasswordComponent, SelectChallengeSectorComponent, CityStatsComponent],
	imports: [
		NgxsModule.forRoot([LoggedInUserState]),
		MomentModule.forRoot(),
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		ToastrModule.forRoot({
			timeOut: 3000,
			preventDuplicates: true,
		}),
		PagesPartialModule,
		ImageCropperModule,
		NgxSkeletonLoaderModule.forRoot(),
		IvyCarouselModule,
		NgMultiSelectDropDownModule.forRoot(),
		CKEditorModule,
		NgxPageScrollModule,
		AngularFireModule.initializeApp(environment.firebase),
		AngularFireDatabaseModule,
	],
	providers: [
		AppinitService,
		{ provide: APP_INITIALIZER, useFactory: runAtAppInit1, deps: [AppinitService], multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{ provide: RouteReuseStrategy, useClass: RouteCache },
		AngularFirestore,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
