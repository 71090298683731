import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { ImageCroppedEvent, base64ToFile } from "ngx-image-cropper";

@Component({
  selector: "Avatar-Upload",
  templateUrl: "./avatar-upload.component.html",
  styleUrls: ["./avatar-upload.component.css"],
})
export class AvatarUploadComponent implements OnInit {
  @Input() avatar:any;
  @Input() Src: string;
  @Output() onSaveImage = new EventEmitter<File>();
  defaultImg: any = "./assets/img/default-avatar.jpg";
  avatarFileChangedEvent: any = "";
  croppedAvatar: any = "";
  avatarRawFileLoaded: boolean;
  constructor() {}

  ngOnInit() {
    
    if(typeof this.Src === "undefined"){
      this.Src = this.defaultImg; 
    }
  }

  avatarChangeEvent(event: any): void {
    this.avatarFileChangedEvent = event;
  }

  avatarImageCropped(event: ImageCroppedEvent) {   
    this.croppedAvatar = event.base64;    
  }

  avatarImageLoaded(image: HTMLImageElement) {
    this.avatarRawFileLoaded = true;
    // show cropper
  }

  avatarCropperReady() {
    // cropper ready
  }

  avatarLoadImageFailed(err) {
    console.log(err, "failed image")
    // show message
  }

  saveEditedImage() {
    const fileBeforeCrop = this.avatarFileChangedEvent.target.files[0];
    // setting preview    
    this.Src = this.croppedAvatar;   
    // converting base64 to BLOB
    const fileBLOB = new File(
      [base64ToFile(this.croppedAvatar)],
      fileBeforeCrop.name,
      {
        type: fileBeforeCrop.type,
      }
    );
    this.onSaveImage.emit(fileBLOB);
    document.getElementById("imageModal").click();
  }

  removeImage(fileInput:HTMLInputElement){
    fileInput.value = ''
    this.croppedAvatar = "";
    this.Src = this.defaultImg;
    this.onSaveImage.emit(null);
    this.avatarFileChangedEvent = "";
    this.avatarRawFileLoaded = false;

  }

}