import { ActivatedRouteSnapshot, RouteReuseStrategy, DetachedRouteHandle, UrlSegment } from '@angular/router';
import {ComponentRef} from '@angular/core'

export class RouteCache implements RouteReuseStrategy{

    storedRoutes = new Map<string, DetachedRouteHandle>();

    flushRouteCache():void{
        this.storedRoutes.forEach((handle: DetachedRouteHandle) => this.destroyComponent(handle));
        this.storedRoutes.clear();
        // console.log('cache cleared');
    }

    // Decides if the route should be stored
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return route.data.reuseRoute === true;
    }

    //Store the information for the route we're destructing
    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        this.storedRoutes.set(route.routeConfig.path, handle);
    }

    //Return true if we have a stored route object for the next route
    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return this.storedRoutes.has(route.routeConfig.path);
    }

    //If we returned true in shouldAttach(), now return the actual route data for restoration
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        return this.storedRoutes.get(route.routeConfig.path);
    }

    //Reuse the route if we're going to and from the same route
    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return future.routeConfig === curr.routeConfig;
    }

    destroyComponent(handle: DetachedRouteHandle): void {
        const componentRef: ComponentRef<any> = handle['componentRef'];
        if (componentRef) {
            componentRef.destroy();
        }
    }

}
