import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { HelpService } from "./help.service";
import { Select } from "@ngxs/store";
import { LoggedInUser } from "../../state/model/loggedinuser.model";
import { LoggedInUserState, LoggedInUserStateModel } from "../../state/loggedinuser.state";
import { Observable } from "rxjs";
@Component({
	selector: "app-help",
	templateUrl: "./help.component.html",
	styleUrls: ["./help.component.css"],
})
export class HelpComponent implements OnInit {
	@Select(LoggedInUserState) loggedInUser$: Observable<LoggedInUserStateModel>;
	loggedInUser: LoggedInUser;

	resources_tb_arr: any = [
		{ id: "1", name: "Policies", a_tag: "#policies" },
		{ id: "2", name: "Model Documents", a_tag: "#model-documents" },
	];

	getting_started_tb_arr: any = [
		{ id: "1", name: "City Innovation exchange", a_tag: "#gett-started-1" },
		{ id: "2", name: "About Platform", a_tag: "#gett-started-2" },
	];

	faq_tb_arr: any = [
		{ id: "1", name: "About CiX", a_tag: "#abt-cix" },
		{ id: "2", name: "Feature & Process - City Administrators", a_tag: "#city-admin" },
		{ id: "3", name: "Feature & Process - Innovators", a_tag: "#innovator" },
		{ id: "4", name: "Promotions", a_tag: "#promotion" },
		{ id: "5", name: "Privacy", a_tag: "#privacy" },
		{ id: "6", name: "Your account", a_tag: "#account" },
	];

	glossary_tb_arr: any = [
		{ id: "1", name: "Solution", a_tag: "#solution" },
		{ id: "2", name: "Challenges", a_tag: "#challenges" },
		{ id: "3", name: "City Administrator", a_tag: "#cityadmin" },
		{ id: "4", name: "Innovator", a_tag: "#innovator_g" },
		{ id: "5", name: "Anchor Process", a_tag: "#anchor" },
	];

	faqs = [
		{
			section_name: "About CiX",
			section_id: "abt-cix",
			content: [
				{
					id: "col_1",
					title: "What are the core objectives of CiX?",
					desc_type: "ul_li",
					status: false,
					li_arr: [
						{ name: "In house support system for Cities to Run Multiple Challenges" },
						{ name: "Reducing market barriers for innovations" },
						{ name: "Transforming cities into Living Labs promoting sustainable growth" },
						{ name: "Institutionalising Open Innovation" },
						{ name: "Innovator-User Co-Creation in Cities involving the Quadruple Helix" },
						{ name: "Catalysing Innovation for Economic Growth, Improved Quality of Life, and Sustainability of cities" },
					],
				},
				{
					id: "col_2",
					title: "What are steps from Challenge to Procurement",
					desc_type: "ul_li",
					status: false,
					li_arr: [
						{ name: "Challenge curation" },
						{ name: "Solution discovery" },
						{ name: "Innovation co-creation" },
						{ name: "Solution validation" },
						{ name: "Solution certification" },
						{ name: "Commercial procurement" },
					],
				},
			],
		},
		{
			section_name: "Feature & Process - City Administrators",
			section_id: "city-admin",
			content: [
				{
					id: "col_3",
					title: "How do I create a Challenge?",
					desc_type: "para",
					status: false,
					desc: "To create a challenge, the “Create Challenge” button from the discover page should be clicked. It will open to a “Challenge Brief” form where the challenge        details are to be provisioned. The challenge will be hosted on the platform once approved by the City Admin.",
				},
				{
					id: "col_4",
					title: " How do I select a solution after posting a Challenge?",
					desc_type: "para",
					status: false,
					desc: " Solutions are assessed & scored with the help of the “CiX Solution Evaluation Rubric.” The assessment parameters include relevance, advantages, value/RoI, viability, and innovator capability. Solutions are selected based on the rank order of their scores.",
				},
			],
		},
		{
			section_name: "Feature & Process - Innovators",
			section_id: "innovator",
			content: [
				{
					id: "col_5",
					title: "How do I create a Challenge?",
					desc_type: "para",
					status: false,
					desc: "To create a challenge, the “Create Challenge” button from the discover page should be clicked. It will open to a “Challenge Brief” form where the challenge        details are to be provisioned. The challenge will be hosted on the platform once approved by the City Admin.",
				},
				{
					id: "col_6",
					title: " How do I select a solution after posting a Challenge?",
					desc_type: "para",
					status: false,
					desc: " Solutions are assessed & scored with the help of the “CiX Solution Evaluation Rubric.” The assessment parameters include relevance, advantages, value/RoI, viability, and innovator capability. Solutions are selected based on the rank order of their scores.",
				},
			],
		},
		{
			section_name: "Promotions",
			section_id: "promotion",
			content: [
				{
					id: "col_7",
					title: "Are there any promotions that we can do for my city/solutions?",
					desc_type: "para",
					status: false,
					desc: "Platform currently does not have an option for promotions directly. Please contact the Program management team regarding this support.",
				},
				{
					id: "col_8",
					title: " Can I promote any specific challenge to invite more Solutions?",
					desc_type: "para",
					status: false,
					desc: " Yes, promotions & outreach can be done for a specific challenge, beyond the platform, to invite more innovators to apply for the challenge through cities  social media handles and outreach activities.",
				},
			],
		},
		{
			section_name: "Privacy",
			section_id: "privacy",
			content: [
				{
					id: "col_9",
					title: "I'm concerned about the data I'm giving to the platform. Is it secure?",
					desc_type: "para",
					status: false,
					desc: "Your trust is our priority and we have implemented responsible and sophisticated, technical and physical controls that are designed to prevent unauthorized access to or disclosure of your content. To also safeguard innovators' privacy of the innovation IP, access to other solutions are restricted",
				},
				{
					id: "col_10",
					title: " Who all has access to the data I'm submitting to the platform?",
					desc_type: "para",
					status: false,
					desc: "  If you are an innovator your solution is visible only to you and city administrators.",
				},
			],
		},
		{
			section_name: "Your account",
			section_id: "account",
			content: [
				{
					id: "col_11",
					title: "Can I add/edit the details of my account?",
					desc_type: "para",
					status: false,
					desc: " Yes, you can add and edit your account details in the profile section of the platform.",
				},
			],
		},
	];

	contactForm: any = {};
	isSubmitted: boolean = false;
	show_msg: boolean = false;
	get_selectedItem: any = 1;
	faq_selectedItem: any = 1;

	constructor(private fb: FormBuilder, private _hs: HelpService) {
		this.loggedInUser$.subscribe((resp) => {
			this.loggedInUser = resp.LoggedInUser;
		});
	}

	ngOnInit() {
		this.contactForm = this.fb.group({
			fname: ["", [Validators.required, Validators.pattern("[a-zA-Z .]*")]],
			lname: ["", [Validators.required, Validators.pattern("[a-zA-Z .]*")]],
			email: ["", [Validators.required, Validators.email]],
			phone: ["", [Validators.required, Validators.pattern("(0|91)?[5-9][0-9]{9}")]],
			shortdesc: ["", [Validators.required, Validators.pattern("[a-zA-Z .]*")]],
			longdesc: [""],
		});
	}

	get contactFormControl() {
		return this.contactForm.controls;
	}

	onSubmit() {
		if (this.contactForm.valid) {
			var post_data = {
				fname: this.contactForm.value.fname,
				lname: this.contactForm.value.lname,
				email: this.contactForm.value.email,
				phone: this.contactForm.value.phone,
				shortdesc: this.contactForm.value.shortdesc,
				longdesc: this.contactForm.value.longdesc,
			};
			// console.log(post_data);
			this._hs.submitContactFormAPI(post_data).subscribe((response: any) => {
				if (response.status == true) {
					this.contactForm.reset();
					this.show_msg = true;
				}
			});
		} else {
			this.isSubmitted = true;
		}
	}

	getstartedActionListner(event, itm, index) {
		this.get_selectedItem = itm.id;
	}

	faqActionListner(event, itm, index) {
		this.faq_selectedItem = itm.id;
	}

	glossaryActionListner(event, itm, index) {
		this.faq_selectedItem = itm.id;
	}
	showIcon(item) {
		// console.log(item);
		item.status = !item.status;
	}
}
