import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Select, Store } from "@ngxs/store";
import { LoggedInUserState, LoggedInUserStateModel } from "../state/loggedinuser.state";
import { LoggedInUser } from "../state/model/loggedinuser.model";
import { Observable } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class FirestoreService {
	loggedUserData: LoggedInUser;
	@Select(LoggedInUserState) loggedInUser$: Observable<LoggedInUserStateModel>;
	constructor(private firestore: AngularFirestore) {
		this.loggedInUser$.subscribe((resp) => {
			this.loggedUserData = resp.LoggedInUser;
			console.log("this.loggedUserData", this.loggedUserData)
		});
	}

	getNotifications() {
		return this.firestore.collection("notifications", (ref) => ref.where("userId", "==",  this.loggedUserData.id).where("read", "==", false).orderBy("createdAt", "desc")).valueChanges();
	}

	updateReadStatus(notificationId: string) {
		let notification = this.firestore.collection("notifications", (ref) => ref.where("id", "==", notificationId));
		notification.snapshotChanges().subscribe((res: any) => {
			let id = res[0].payload.doc.id;
			this.firestore.collection("notifications").doc(id).update({ read: true });
			console.log("updated");
		});
	}
	updateReadStatusAll() {
		let notification = this.firestore.collection("notifications", (ref) => ref.where("userId", "==",  this.loggedUserData.id));
		notification.snapshotChanges().subscribe((res:any)=>{
			res.forEach(e => {
				let id = e.payload.doc.id;
				// console.log("updateall Id", id);
				this.firestore.collection("notifications").doc(id).update({ read: true });
			});
			console.log("All notifications Updated");
		})
	}
	getAllNotifications() {
		return this.firestore.collection("notifications", (ref) => ref.where("userId", "==",  this.loggedUserData.id).orderBy("createdAt", "desc")).valueChanges();
	}
}
