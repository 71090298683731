import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./auth.guard";
import { ChallengesMultiSelectComponent } from "./challenge/challenges-multi-select/challenges-multi-select.component";
import { HomeComponent } from "./pages/home/home.component";
import { HelpComponent } from "./pages/help/help.component";
import { LoginComponent } from "./users/login/login.component";
import { ForgotPasswordComponent } from "./users/forgot-password/forgot-password.component";
import { SelectChallengeSectorComponent } from "./users/select-challenge-sector/select-challenge-sector.component";

const routes: Routes = [
	{
		path: "",
		component: HomeComponent,
	},
	{
		path: "home",
		component: HomeComponent,
	},
	{
		path: "help",
		component: HelpComponent,
	},
	{
		path: "login",
		component: LoginComponent,
	},
	{
		path: "register",
		loadChildren: "./register/register.module#RegisterModule",
	},
	{
		path: "challenges-multiselect",
		component: ChallengesMultiSelectComponent,
		canActivate: [AuthGuard],
	},
	{
		path: "login/forgot-password",
		component: ForgotPasswordComponent,
	},
	{
		path: "select-challenge-sector",
		component: SelectChallengeSectorComponent,
	},
	{
		path: "discover",
		loadChildren: () => import("./discover/discover.module").then((m) => m.DiscoverModule),
		canActivate: [AuthGuard],
	},
	{
		path: "challenge",
		loadChildren: () => import("./challenge/challenge.module").then((m) => m.ChallengeModule),
		canActivate: [AuthGuard],
	},
	{
		path: "solution",
		loadChildren: () => import("./solution/solution.module").then((m) => m.SolutionModule),
		canActivate: [AuthGuard],
	},
	{
		path: "provider",
		loadChildren: () => import("./provider/provider.module").then((m) => m.ProviderModule),
		canActivate: [AuthGuard],
	},
	{
		path: "cities",
		loadChildren: () => import("./cities/cities.module").then((m) => m.CitiesModule),
		canActivate: [AuthGuard],
	},
	{
		path: "seeker",
		loadChildren: () => import("./seeker/seeker.module").then((m) => m.SeekerModule),
		canActivate: [AuthGuard],
	},
	{
		path:"notifications",
		loadChildren:()=> import("./notifications/notifications.module").then((m) => m.NotificationsModule),
		canActivate: [AuthGuard],
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			scrollPositionRestoration: "enabled",
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
