import { Component, OnInit, OnChanges } from "@angular/core";
import { FirestoreService } from "../../../services/firestore.service";
import { Router } from "@angular/router";
import * as moment from "moment";

@Component({
	selector: "app-notifier",
	templateUrl: "./notifier.component.html",
	styleUrls: ["./notifier.component.css"],
})
export class NotifierComponent implements OnInit, OnChanges {
	notificationCount: number = 0;
	notifications: any;

	constructor(private fs: FirestoreService, private router: Router) {}

	ngOnInit() {
		this.fs.getNotifications().subscribe(async (data: any) => {
			this.notifications = data;
			await this.notifications.map((n) => (n.timestamp = moment(n.createdAt.seconds * 1000).fromNow()));
			this.notificationCount = data.length || 0;
		});
		// console.log("notifications unread", this.notifications )
	}

	ngOnChanges() {}

	handleNotificationRedirect(reDirectRoute: string, notificationId: string) {
		this.fs.updateReadStatus(notificationId);
		this.router.navigateByUrl(reDirectRoute);
	}
	viewAll(){
		this.router.navigateByUrl('notifications')
	}
}
